import {observer} from "mobx-react";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Radio, Spin, Input, Card, Switch, Alert, Space, Tabs, Select, Divider, Skeleton} from "antd";
import utils, {callWithCatch, pp} from "../components/utils";
import "react-multi-date-picker/styles/layouts/mobile.css"

import DatePicker, {Calendar, DateObject} from "react-multi-date-picker"
import Device from "../components/Device";
import User from "../components/User";
import MyTabs from "../components/MyTabs";
import DataTable from "../components/DataTable";
import {Showable} from "../components/Switcher";

const heb_locale = {
  name: "heb_ar",
  months: [
    ["ינואר", "jan"],
    ["פברואר", "feb"],
    ["מרץ", "mar"],
    ["אפריל", "apr"],
    ["מאי", "may"],
    ["יוני", "jun"],
    ["יולי", "jul"],
    ["אוגוסט", "aug"],
    ["ספטמבר", "sep"],
    ["אוקטובר", "oct"],
    ["נובמבר", "nov"],
    ["דצמבר", "dec"],
  ],
  weekDays: [
    ["שבת", "ש"],
    ["ראשון", "א"],
    ["שני", "ב"],
    ["שלישי", "ג"],
    ["רביעי", "ד"],
    ["חמישי", "ה"],
    ["שישי", "ו"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

let colors = {
  ignore: ["#64748b", "פגרה/סופש"],
  count: ["#84cc16", "יום במניין"],
  last: ["#ef4444", "תאריך אחרון"],
  from: ["#0ea5e9", "תאריך התחלתי"],
}

let MM = () => {
  let data = [
    "from", "count", "ignore", "last"
  ]

  return <Card size="small" style={{width: Device.xs ? "100%" : "140px"}}>
    {data.map(sym => {
      let [c, title] = colors[sym];
      return <div style={{padding: "2px"}} key={sym}>
        <Space key={sym}>
          <div style={{height: "12px", width: "12px", backgroundColor: c}}></div>
          <div>{title}</div>
        </Space>
      </div>
    })}
  </Card>
};

let Cal = ({calc}) => {
  let currentDate = new DateObject(calc.last);

  if (!Device.xs)
    currentDate = currentDate.subtract(calc.months - 1, 'M');

  return <Calendar
    multiple
    className={Device.xs && "rmdp-mobile"}
    numberOfMonths={Device.xs ? 1 : calc.months}
    locale={heb_locale}
    currentDate={currentDate}
    mapDays={({date, isSameDate}) => {
      let props = {
        disabled: true,
        style: {
          borderRadius: "1px",
          color: '#fff',
        }
      }

      let df = date.format("YYYY-MM-DD");

      if (calc.ignore.includes(df)) {
        // props.style.color = "#475569";
        props.style.backgroundColor = colors.ignore[0];
      } else if (calc.count.includes(df)) {
        // props.style.color = "#0284c7";
        props.style.backgroundColor = colors.count[0];
      } else if (df === calc.last)
        props.style.backgroundColor = colors.last[0];
      else if (df === calc.from)
        props.style.backgroundColor = colors.from[0];
      else
        props.style.color = "#000";

      return props;
    }}
  />
}

export default observer(() => {
  return <div className="" style={{direction: "rtl", padding: "1rem"}}>
    <MyTabs>
      <Tabs.TabPane tab="כאב וסבל" key="pain" show={User.isAdmin()}>
        <Pain/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="מועדים" key="dates">
        <Dates/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="הפסדי שכר לעתיד" key="damages">
        <Damages/>
      </Tabs.TabPane>
    </MyTabs>
  </div>
})

let FormTitle = ({t}) => <Divider orientation="right" orientationMargin={2}>{t}</Divider>

let Pain = observer(() => {
  const [form] = Form.useForm();
  let [res, setRes] = useState();
  let [loading, setLoading] = useState(false);

  let calc = (params) => {
    callWithCatch({
      url: "/calculators",
      method: 'post',
      params: {
        ...params,
        birthdate: params.birthdate.format(),
        accident_date: params.accident_date.format(),
        calc_date: params.calc_date.format()
      },
      setLoading,
      onSuccess: data => {
        setRes(data.calc)
      }
    })
  }

  const accident_type = Form.useWatch('accident_type', form);

  const valuesChanged = useCallback(
    _.debounce((cvs, vals) => {
      if (!vals.accident_date || !vals.birthdate || !vals.calc_date)
        return;

      pp(vals)
      if (vals.accident_type === "accident" && !vals.disability)
        return;

      form
        .validateFields()
        .then((values) => {
          pp(vals)
          calc(vals)
        })
    }, 500),
    []
  );

  return <div className="" style={{direction: "rtl", padding: "0"}}>
    <Form labelCol={{span: Device.xs ? 1 : 10}}
          layout={Device.xs && "vertical"}
          onValuesChange={valuesChanged}
          form={form} autoComplete="off"
          initialValues={{accident_type: "accident", calc_date: new DateObject()}}>
      <Space direction={Device.xs && "vertical"} size={16}>
        <div style={{width: Device.xs ? "100%" : "300px"}} className="space-y-4">
          <Card size="small">
            <Form.Item name="calc_date" label="תאריך החישוב" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
            <Form.Item name="birthdate" label="תאריך לידה" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
            <Form.Item name="accident_date" label="תאריך תאונה" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
            <Form.Item name="accident_type" label="סוג תאונה" rules={[{required: true}]}>
              <Radio.Group>
                <Radio value="accident">פגיעה</Radio>
                <Radio value="death">מוות</Radio>
              </Radio.Group>
            </Form.Item>
            <Showable v={accident_type === 'accident'}>
              <Form.Item name="disability" label="נכות רפואית" rules={[{required: true}]}>
                <Input prefix="%" style={{width: "100px"}}/>
              </Form.Item>
              <Form.Item name="days" label="ימי אישפוז">
                <Input style={{width: "50px"}}/>
              </Form.Item>
            </Showable>
          </Card>
        </div>
        <div style={{width: Device.xs ? "100%" : "400px"}} className="space-y-4">
          <Spin spinning={loading}>
            {res && <DataTable data={res} style={{width: "100%"}}/>}
          </Spin>
        </div>
      </Space>
    </Form>
  </div>
});

let Damages = observer(() => {
  const [form] = Form.useForm();
  let [res, setRes] = useState();
  let [loading, setLoading] = useState(false);

  let calc = (params) => {
    callWithCatch({
      url: "/calculators",
      method: 'post',
      params: {
        ...params,
        birthdate: params.birthdate.format(),
        accident_date: params.accident_date.format(),
        calc_date: params.calc_date.format()
      },
      setLoading,
      onSuccess: data => {
        pp(data)
        setRes(data.calc)
      }
    })
  }

  const valuesChanged = useCallback(
    _.debounce((cvs, vals) => {
      if (!vals.base || !vals.employment || !vals.accident_date || !vals.base || !vals.disability)
        return;

      form
        .validateFields()
        .then((values) => {
          pp(vals)
          calc(vals)
        })
    }, 500),
    []
  );

  const calc_date = Form.useWatch('calc_date', form);
  const birthdate = Form.useWatch('birthdate', form);

  let pre21 = calc_date && birthdate && (calc_date.year - birthdate.year < 20);

  return <div className="" style={{direction: "rtl", padding: "0"}}>
    <Form labelCol={{span: Device.xs ? 1 : 10}}
          layout={Device.xs && "vertical"}
          onValuesChange={valuesChanged}
          form={form} autoComplete="off"
          initialValues={{gender: "male", employment: "employed", actuar: 100, calc_date: new DateObject(), earning_age: 21}}>
      <Space direction={Device.xs && "vertical"} size={16}>
        <div style={{width: Device.xs ? "100%" : "300px"}} className="space-y-4">
          <Card title="פרטים אישיים" size="small">
            <Form.Item name="gender" label="מין" rules={[{required: true}]}>
              <Radio.Group>
                <Radio value="male">גבר</Radio>
                <Radio value="female">אישה</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="birthdate" label="תאריך לידה" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
          </Card>
          <Card title="תעסוקה" size="small">
            <Form.Item name="employment" label="סוג" rules={[{required: true}]}>
              <Radio.Group>
                <Radio value="employed">שכיר</Radio>
                <Radio value="self">עצמאי</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="base" label="בסיס שכר" rules={[{required: true}]}>
              <Input suffix="₪" style={{width: "100px"}}/>
            </Form.Item>
          </Card>
          <Card title="תאונה" size="small">
            <Form.Item name="accident_date" label="תאריך תאונה" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
            <Form.Item name="disability" label="נכות רפואית" rules={[{required: true}]}>
              <Input prefix="%" style={{width: "100px"}}/>
            </Form.Item>
          </Card>
        </div>
        <div style={{width: Device.xs ? "100%" : "400px"}} className="space-y-4">
          <Card title="חישוב" size="small">
            <Form.Item name="calc_date" label="תאריך החישוב" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
            <Form.Item name="actuar" label="אחוז אקטוארי">
              <Select style={{width: "100px"}}>
                <Option value="100">100%</Option>
                <Option value="70">70%</Option>
                <Option value="50">50%</Option>
                <Option value="30">30%</Option>
              </Select>
            </Form.Item>
            { pre21 && <Form.Item name="earning_age" label="גיל תחילת ההשתכרות" rules={[{required: true}]}>
              <Radio.Group>
                <Radio value={21}>21</Radio>
                <Radio value={18}>18</Radio>
              </Radio.Group>
            </Form.Item> }
            <Spin spinning={loading}>
              {res && <DataTable data={res} style={{width: "100%"}}/>}
            </Spin>
            {/*<Switch2 v={loading}>*/}
            {/*  <Skeleton />*/}
            {/*  {res && <DataTable data={res} style={{width: "100%"}}/>}}*/}
            {/*</Switch2>*/}
          </Card>
        </div>
      </Space>
    </Form>
  </div>

});

let Dates = observer(() => {
  const [form] = Form.useForm();
  let [res, setRes] = useState();
  let [loading, setLoading] = useState();

  const isCount = Form.useWatch('count', form);

  let calc = (params) => {
    callWithCatch({
      url: "/calculators",
      method: 'post',
      params: {...params, from: params.from.format()},
      setLoading,
      onSuccess: data => {
        setRes(data.calc)
      }
    })
  }

  let reset = () => {
    let params = {...form.getFieldsValue()};

    if (!params.from || !params.days || !params.from.format)
      return;

    // setRes(null);
    form
      .validateFields()
      .then((values) => {
        calc(params);
      })
  }

  let countTitle = isCount ? "הפגרה אינה במניין הימים" : "הפגרה במניין הימים"

  let resEl = res && <Space size={8} direction={"vertical"}>
    <Cal calc={res} key={res.last}/>
    <MM/>
  </Space>

  return <div className="" style={{direction: "rtl", padding: "1rem"}}>
    <Space direction={Device.xs && "vertical"} size={16}>
      <div style={{width: Device.xs ? "100%" : "230px"}}>
        <Card title="מחשבון מועדי הגשה" extra={loading && <Spin/>}>
          <Form layout="vertical" onFinish={calc} onChange={reset} form={form} autoComplete="off"
                initialValues={{from: {}, count: true}}>
            <Form.Item name="days" label="ימים" rules={[{required: true}]}>
              <Input style={{width: "50px"}}/>
            </Form.Item>
            <Form.Item name="count" label={countTitle} valuePropName="checked">
              <Switch onChange={reset}/>
            </Form.Item>
            <Form.Item name="from" label="מתאריך" rules={[{required: true}]}>
              <DatePicker locale={heb_locale}
                          format="DD/MM/YYYY"
                          onChange={reset}
                          className={Device.xs && "rmdp-mobile"}
                          calendarPosition="left-top"
                          mobileLabels={{
                            OK: "שמור",
                            CANCEL: "בטל",
                          }}
              />
            </Form.Item>
          </Form>
          {res && <div>
            <div style={{marginBottom: "2px"}}>תאריך אחרון להגשה:</div>
            <Alert type="error" message={res.last_view}/>
          </div>}
        </Card>
      </div>
      {resEl}
    </Space>
  </div>
});