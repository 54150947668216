import React from 'react';
import {
  Tabs,
} from "antd";
import Device from "./Device";

export default ({children, ...props}) => {
  return <Tabs tabBarStyle={{marginLeft: Device.xs ? "1rem" : 0}} {...props}>
    {_.reject(children, c => c.props.show === false || c.props.hidden)}
  </Tabs>
}