import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";

import {Drawer} from "antd";
import Payment from "./Payment";


export default observer(({store}) => {
  let close = () => store.pricing = false;

  return <Drawer
    width={window.innerWidth < 1200 ? "80%" : "60%"}
    closable={false}
    title="הרשמת מנויים"
    placement="left"
    onClose={close}
    visible={store.pricing}
  >
    {store.pricing && <Payment/>}
  </Drawer>
});