import {observable} from "mobx";
import store from 'store';

// import _ from 'lodash';

export default observable({
  searches: store.get('recentSearches') || [],
  docs: store.get('recentDocs') || [],

  clear() {
    store.set('recentSearches', []);
  },

  removeSearch(term) {
    let list = Array.from(this.searches);

    _.remove(list, o => o.term === term)

    store.set('recentSearches', list);
    this.searches = list;
  },

  removeDoc(obj) {
    let list = Array.from(this.docs);

    _.remove(list, o => o.id === obj.id)
    store.set('recentDocs', list);

    this.docs = list;
  },

  isEmpty() {
    return this.docs.length === 0;
  },

  addSearch(term, filters) {
    let list = Array.from(this.searches);
    filters.term=term;

    list.unshift(filters);
    list = _.take(_.uniqBy(list, 'term'), 10);
    store.set('recentSearches', list);

    this.searches = list;
  },

  addDoc(doc) {
    let list = Array.from(this.docs);

    list.unshift({ id: doc.id, case_num: doc.case_num, case_title: doc.case_title });
    list = _.take(_.uniqBy(list, 'id'), 10);
    store.set('recentDocs', list);

    this.docs = list;
  },
});

