import React from 'react';

export default ({data, thStyle, cs, ...props}) => {
  return <table className={"w-full text-sm text-right rtl:text-right text-gray-500 dark:text-gray-400 " + cs} {...props}>
    <tbody>
      {data.map(d => {
        return <tr className="bg-white" key={d[0]}>
          <th scope="row" className="py-1 pr-4 font-medium whitespace-nowrap" style={thStyle}>
            {d[0]}
          </th>
          <td className="w-full text-gray-900 ltr">
            {d[1]}
          </td>
        </tr>
      })}
    </tbody>
  </table>
}

