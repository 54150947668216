import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Card, Table} from "antd";

export default observer(({teamId}) => {
  let [users, setUsers] = useState(null);

  let load = async () => {
    let data = (await axios.get('/teams/' + teamId)).data;

    setUsers(data.users);
  };

  useEffect(() => {
    load();
  }, [teamId]);

  let columns = [
    {
      title: 'שם',
      dataIndex: 'name',
    },
    {
      title: 'אימייל',
      dataIndex: 'email',
    },
    {
      title: 'טלפון',
      dataIndex: 'phone'
    },
    // {
    //   title: 'ימים',
    //   dataIndex: 'days',
    //   sorter: (a, b) => a.days - b.days,
    //   sortDirections: ['descend'],
    // },
  ];

  return <div>
    <Card title="משתמשים">
      <Table
        rowKey="email"
        columns={columns} dataSource={users} loading={!users} pagination={false}
      />
    </Card>
  </div>;
});