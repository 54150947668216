import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {callWithCatch, pp} from "./utils.js";
import MyWS from "./MyWS.js";
import {Card, Popover, Space, Spin, Tooltip} from "antd";
import {Bot} from "lucide-react";
import HtmlDiv from "./HtmlDiv.js";
import User from "./User.js";
import {NiceDrawer} from "./NiceDrawer.js";
import NiceModal from "@ebay/nice-modal-react";

const AiStream = observer(({q, doc_id, loading, setLoading, loadArticle, cachedResult}) => {
    const [result, setResult] = useState(cachedResult);

    let run = async () => {
        setLoading(true);
        setResult(false);
        callWithCatch({
            url: `/searches/chat`,
            method: 'post',
            params: {relevancy: true, q, doc_id},
            onErr: () => {
                setLoading(false)
            },
            onSuccess: data => {
                let sub;
                if (data.text || data.relevancy) {
                    setLoading(false);
                    setResult(data)
                }

                if (data.sub_to)
                    sub = MyWS(data.sub_to, r => {
                        setResult(r)
                        if (r.done) {
                            setLoading(false);
                            sub.unsubscribe();
                        }
                    })
            }
        })
    }

    useEffect(() => {
        !cachedResult && run();
    }, [q])


    let title = <Space><Bot/><Spin/></Space>;
    if (result?.done || cachedResult)
        title = null;

    if (result?.err) {
        pp(result?.err)
        title = <Alert type="error" message="חלה שגיאה ביצירת התשובה" />
    }

    let open = q => {
        NiceModal.remove(RelevancyDrawer);
        loadArticle(doc_id, q);
    }

    return <div className="w-full sm:flex-grow">
        {title}
        {result &&
            <div style={{fontSize: "14px", whiteSpace: "pre-line"}} className="space-y-4">
                <HtmlDiv html={result.relevancy}/>
                {!!result.quotes.length && <div className="space-y-4">
                    <div className="text-lg">ציטוטים:</div>
                    {result.quotes.map(q => <div key={q}><a onClick={() => open(q)}>{q}</a></div>)}
                </div>}
                {result.done &&
                    <div style={{fontSize: "10px", color: "#9CA3AF"}}>הAI יכול לעשות טעויות. אנא ודאו את
                        תשובותיו עם הפסיקה.</div>}
            </div>}
    </div>
})

const ArticleDate = React.memo(({date}) => <Tooltip title={date}>
    <span>({date.replace(/^\d+\.\d+\./, "")})</span>
</Tooltip>);


let RelevancyDrawer = NiceModal.create(observer(({ q, doc, loadArticle, cachedResult }) => {
    const [loading, setLoading] = useState(true);

    let open = () => {
        NiceModal.remove(RelevancyDrawer);
        loadArticle(doc.id);
    }

    let relevancyTitle = <div onClick={open}>
        <div className="hit-desc" style={{fontSize: "12px", color: "#9CA3AF", display: "flex", flexDirection: "row"}}>
            <span>{doc.case_num} {<ArticleDate date={doc.date} />} &#8226; {doc.case_type} &#8226; {doc.court}</span>
        </div>
        <div><a>{doc.case_title}</a></div>
    </div>

    return <NiceDrawer title={relevancyTitle}>
        <AiStream q={q} doc_id={doc.id} loading={loading} setLoading={setLoading} loadArticle={loadArticle} cachedResult={cachedResult} />
    </NiceDrawer>
}));

export default observer(({q, doc, loadArticle, cachedResult}) => {
    if (!q)
        return null;

    let openPopup = e => {
        e.preventDefault();
        User.track('relevancy.click');
        // setShow(true);
        NiceModal.show(RelevancyDrawer, {q, doc, loadArticle, cachedResult});
    }

    return <a href="#" onClick={openPopup}><span className="hit-result-link">למה זה רלוונטי?</span></a>
});

export const OpenRelevancyDrawer = (params) => NiceModal.show(RelevancyDrawer, params);