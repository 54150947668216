import {message, Space} from "antd";
import React from "react";

export const TextCopy = ({text, copyText, direction, prefix, successMsg}) => {
  let linkCopy = e => {
    e.preventDefault();

    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(copyText);
      message.success(successMsg || `${text || copyText} copied!`);
    }
  }

  copyText = copyText || text;

  return <Space direction={direction}>
    { prefix && <div>{prefix}</div> }
    <a onClick={linkCopy}>{text || "copy"}</a>
  </Space>
}

export default ({url, direction, text, showAsText, showLink, successMsg}) => {
  let linkCopy = e => {
    e.preventDefault();

    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(url);
      message.success(successMsg || `${url} copied!`);
    }
  }

  return <Space direction={direction}>
    {showLink && <a href={url} target="_blank">{url}</a>}
    {showAsText && <span>{url}</span>}
    <a href={url} onClick={linkCopy}>{text || "copy"}</a>
  </Space>
}
