import actioncable from 'actioncable';
import axios from "axios";
import User from "../components/User";
import {
  Modal,
} from "antd";

let prefix = import.meta.env.DEV ? "ws://localhost:3007" : "wss://www.dinrega.com";

export default (store) => {
  const cable = actioncable.createConsumer(`${prefix}/cable`)
  cable.subscriptions.create("UsersChannel", {
    received(data) {
      if (data.logout === true)
        window.location.href = "/logout";

      if (data.logout && data.logout.cid === User.cid()) {
        axios.get("/logout?async=1");

        Modal.warning({
          title: data.logout.title,
          content: data.logout.content,
          okText: "התחברו מחדש",
          onOk: () => window.location.href = "/",
        });
      }

      if (data.alert)
        alert(data.alert);
    }
  });
}
