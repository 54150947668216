import React, {useEffect, useState} from 'react';
import api from "./Api";
import User from "./User";
import {observer} from "mobx-react";
import {Form, Input, Button, Radio, Card, Space, Typography, Row, Col, Spin, Modal, Select} from 'antd';

const {Title} = Typography;

import Logo from '../../assets/images/dinrega-logo.png'
import Device from "./Device";
import OurCustomers from "./OurCustomers";

export default observer(() => {
  const [form1] = Form.useForm();
  const [formJ] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [justiceModel, setJusticeModel] = useState(false);

  let cid = User.cid();

  let errPopup = msg => {
    Modal.error({
      title: msg,
      okText: "סגור"
    });
  }

  let jds = [
    "סניגוריה ציבורית - מחוז ת\"א",
    "פרקליטות מחוז דרום (אזרחי)",
    "פרקליטות מחוז ירושלים (אזרחי)",
    "פרקליטות מחוז מרכז (אזרחי)",
    "פרקליטת מחוז מרכז (פלילי)",
    "פרקליטות מחוז תל אביב",
    "מחלקת בגצים",
    "מחלקה פלילית",
    "מחלקת סייבר",
  ];

  let login = async ({password, email, replace, name}) => {
    setLoading(true)
    let data = (await api.post("/login", {password, email, cid, replace, name})).data;

    if (data.err)
      errPopup(data.err);
    else if (data.register_j)
      setJusticeModel(true);
    else if (data.register) {
      let name = prompt("מה שמך המלא?")
      if (name && name.length > 4)
        login({password, email, name});
      else
        errPopup("שגיאה בשם")
    } else if (data.single) {
      if (confirm(data.single))
        login({password, email, replace: true});
    } else {
      location.href = "/";
    }
    setLoading(false)
  };

  let codeLogin = async ({passcode, replace}) => {
    let data = (await api.post("/login", {passcode, cid, replace})).data;

    if (data.err)
      errPopup("קוד גישה שגוי");
    else if (data.single) {
      if (confirm(data.single))
        codeLogin({passcode, replace: true});
    } else
      location.reload();
  };

  let submitJustice = () => formJ.submit();
  let registerJ = async vals => {
    setLoading(true)
    let f1vs = form1.getFieldsValue();

    let data = (await api.post("/login", {password: f1vs.password, name: vals.name, email: f1vs.email, department: vals.department})).data;
    if (data.err)
      errPopup("שגיאה בהרשמה");
    else
      location.href = "/";
  }

  let justiceModal = <Modal visible={justiceModel} okButtonProps={{loading}} onCancel={() => setJusticeModel(false)} onOk={submitJustice} title="פרטים נוספים" okText="סיום הרשמה" cancelText="ביטול" width="300px">
    <Form form={formJ} onFinish={registerJ} layout="vertical" autoComplete="off" id="login-form-j">
      <Form.Item name="name" label="שם מלא" rules={[{ required: true, message: 'נא לרשום שם' }]}>
        <Input size="large" autoComplete="name" />
      </Form.Item>
      <Form.Item name="department" label="מחלקה" rules={[{ required: true, message: 'נא לבחור מחלקה' }]}>
        <Select
          defaultValue="בחרו מחלקה"
          options={jds.map(v => ({label: v, value: v}))}/>
      </Form.Item>
    </Form>
  </Modal>

  let justice = location.href.match(/justice/);
  let numLink  = Device.xs ? <a style={{color: 'white', textDecoration: "underline"}} target="_blank" href="https://api.whatsapp.com/send?phone=972545764700">054-5764700</a> : "054-5764700";
  let email = <a style={{color: 'white', textDecoration: "underline"}} href="mailto:office@fridman-adv.com">office@fridman-adv.com</a>

  let cd = gon.customLogin;
  let title = cd ? "כניסה למערכת" : "כניסת מנויים"

  if (cd && cd.closed)
    return <div id="login" style={{minHeight: "100vh"}}>
      <div>
        <div className="content-center" style={{textAlign: "center"}}><img src={Logo}/></div>
        <div style={{color: 'white', fontSize: "16px", marginBottom: "5px", marginTop: "-35px"}}>
          <div style={{fontSize: "45px", fontWeight: "bold", marginBottom: "10px"}}>חיפוש פסיקה בעזרת בינה מלאכותית</div>
          {cd.welcome}.
          <br/>
          <br/>
          לשאלות ותמיכה ניתן לפנות לעו"ד רונן פרידמן בדוא"ל: {email} או בוואטסאפ {numLink}
        </div>
      </div>
    </div>


  return <div>
    {justiceModal}
    <div id="login">
      <div>
        <div className="flex justify-center">
          <a href="/"><img src={Logo}/></a>
        </div>
        {cd && <div style={{color: 'white', fontSize: "16px", marginBottom: "5px", marginTop: "-35px"}}>
          <div style={{fontSize: "45px", fontWeight: "bold", marginBottom: "10px"}}>חיפוש פסיקה בעזרת בינה מלאכותית</div>
           {cd.welcome}!
          <br/>
          <br/>
          כדי להכנס למערכת, אנא הזינו את הפרטים הבאים:
          <br/>
          1. <b>אימייל</b>: הדוא"ל האישי בעבודה (חובה סיומת {cd.email})
          <br/>
          2. <b>סיסמא</b>: מספר הטלפון הנייד שלכם.
          <br/>
          3. {cd.extra}
          <br/>
          <br/>
          לשאלות ותמיכה ניתן לפנות לעו"ד רונן פרידמן בדוא"ל: {email} או בוואטסאפ {numLink}
        </div>}
        <Row gutter={32}>
          <Col flex={1}>
          </Col>
          <Col flex={1}>
            <div className="my-card">
              <Title level={2}>{title}</Title>
              <Form form={form1} onFinish={login} layout="vertical" autoComplete="on" id="login-form">
                <Form.Item name="email" label="אימייל" required>
                  <Input size="large" autoComplete="email" style={{direction: "ltr"}}/>
                </Form.Item>
                <Form.Item name="password" label="סיסמא" required>
                  <Input.Password size="large" autoComplete="password"/>
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  כניסה
                </Button>
              </Form>
            </div>
          </Col>

          <Col flex={1}>
            {/*<div className="my-card">*/}
              {/*<Title level={2}>כניסת קוד גישה זמני</Title>*/}
              {/*<Form form={form2} onFinish={codeLogin} layout="vertical">*/}
              {/*  <Form.Item name="passcode">*/}
              {/*    <Input size="large"/>*/}
              {/*  </Form.Item>*/}
              {/*  <Button type="primary" htmlType="submit" loading={loading}>*/}
              {/*    כניסה*/}
              {/*  </Button>*/}
              {/*</Form>*/}
            {/*</div>*/}
          </Col>
        </Row>
      </div>
    </div>
    {!justice && <OurCustomers />}
    <br/>
    {cd && <div style={{width: "800px", margin: "20px auto"}}>
      <div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative'}}>
        <div className="wistia_responsive_wrapper" style={{height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}}>
          <iframe src={"https://fast.wistia.net/embed/iframe/xonyt7vgte?videoFoam=true"} title="הדרכת דין רגע"
                  allow="autoplay; fullscreen" frameBorder="0" scrolling="no"
                  className="wistia_embed" name="wistia_embed" width="100%" height="100%" />
        </div>
      </div>
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async/>
    </div>}
    <br/>
  </div>
})