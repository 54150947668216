import {Form, Modal, Spin} from "antd";
import React from "react";
import {observer} from "mobx-react";

import { ElfsightWidget } from 'react-elfsight-widget';


export default observer(({store}) => {
  return <Modal footer={null} visible={true} onCancel={() => store.screen = "search"}>
    <ElfsightWidget widgetID="d6b69d7e-ded3-406d-b66a-e21e60f0a04b">
      <Spin />
    </ElfsightWidget>
  </Modal>
});
