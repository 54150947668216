import React, {useEffect} from "react";
import {antdDrawer, useModal} from "@ebay/nice-modal-react";
import {Drawer} from "antd";

export const NiceDrawer = ({ children, ...props }) => {
    const modal = useModal();

    return <Drawer {...antdDrawer(modal)}
                   {...props}
    >
        {children}
    </Drawer>
};
