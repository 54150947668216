import React, {useEffect, useState} from 'react';
import { ConfigProvider } from 'antd';
// import './App.css';

import {observer} from 'mobx-react'
import SearchApp from "./components/SearchApp";
import Login from "./components/Login";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import NiceModal from '@ebay/nice-modal-react';

Bugsnag.start({
  apiKey: '51cc23a1d7560165fc33eaa13ecef29c',
  enabledReleaseStages: [ 'production', 'staging' ],
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default observer(() => {
  let inner = () => {
    if (!gon.user)
      return <Login />;

    return <SearchApp/>;
  };

  return <ConfigProvider direction="rtl">
    <ErrorBoundary>
      <NiceModal.Provider>
        {inner()}
      </NiceModal.Provider>
    </ErrorBoundary>
  </ConfigProvider>
});