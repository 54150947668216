import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {
  ExceptionOutlined,
  FileSyncOutlined,
  BookOutlined
} from "@ant-design/icons";
import MyDrawer from "./MyDrawer";
import {observable} from "mobx";
import User from "./User";
import {Skeleton} from "antd";
import Api from "./Api";
import {addRoute} from "./Router";
import Device from "./Device";
import ArticleStore from "./ArticleStore";
import Searcher from "./Searcher";
import {ColorArea} from "./ColorArea";
import LinkCopy, {TextCopy} from "./LinkCopy";

export const BriefDrawerStore = observable({
  brief: null,
  mode: 'claims',
  opened: false,

  load: async (id, mode) => {
    // addRoute({aid: id},null, "/docs/" + id)
    BriefDrawerStore.opened = true;
    BriefDrawerStore.mode = mode || "claims";

    let data = (await Api.get("/briefs/" + id)).data;

    BriefDrawerStore.brief = data.brief;
  },

  close: () => {
    BriefDrawerStore.opened = false;
    BriefDrawerStore.brief = null;
    addRoute({}, null, "/");
  }
});

let RefsArea = observer(({children}) => {
  let brief = BriefDrawerStore.brief;
  let nums = brief.ref_case_nums;

  if (!nums || !nums.length)
    return children;

  let rs = [new RegExp(nums.join("|"))];
  // let rs = nums.map(n => new RegExp(n));

  let click = e => {
    let el = e.target;
    if (el.nodeName.match(/mark/i) && !el.className.match(/mark-brief/)) {
      e.stopPropagation();
      Searcher.search(el.innerText);
    }
  }

  return <div className="refs" onClick={click}>
    <ColorArea regexps={rs}>
      {children}
    </ColorArea>
  </div>
});

let FocusLine = observer(({section, sectionStr, setMode, children}) => {
  let click = () => {
    if (window.getSelection().toString().length)
      return;

    ArticleStore.locate = section || sectionStr.replace(/<\/?[^>]+(>|$)/g, "");
    setMode("article");
  };

  return <div className="focus-section" onClick={click}>
    {section || children || sectionStr}
  </div>
});

let Refs = ({setMode}) => {
  let brief = BriefDrawerStore.brief;
  let nums = brief.ref_case_nums;

  if (!nums.length)
    return null;

  let r = new RegExp(nums.join("|"));
  let lines = _.filter(brief.refs_parts, l => l.match(r));

  return <div>
    {lines.map((p,i) => <FocusLine sectionStr={p} key={i}>
      <RefsArea>
        {p}
      </RefsArea>
    </FocusLine>)}
  </div>
}

export const BriefDrawer = observer(({loadArticle}) => {
  if (!BriefDrawerStore.opened)
    return null;

  let brief = BriefDrawerStore.brief;
  let tabs = [];
  let inner = <Skeleton active />;
  let header;

  let handleModeChange = e => {
    let k = (e.target && e.target.value) || e.key || e;

    BriefDrawerStore.mode = k;

    _.defer(() => {
      let drawer = document.querySelector(".ant-drawer-body");

      if (drawer)
        drawer.scrollTop = 0;
    }, 500)
  };

  if (brief) {
    let titles = brief.titles;

    tabs = [
      // ["claims", titles[0], <ContainerOutlined />],
      // ["responses", titles[1], <RotateRightOutlined />],
      ["claims", titles[0], <ExceptionOutlined />],
      ["responses", titles[1], <FileSyncOutlined />],
    ];

    if (!brief.responses.length)
      tabs.pop();

    let inTopic = "בעניין";
    let cs = brief[BriefDrawerStore.mode] || [];

    let click = () => {
      if (User.isAdmin())
        loadArticle(brief.doc_id);
    }

    let withRefs = !!brief.refs_parts.length;
    if (withRefs)
      tabs.push(["refs", Device.xs ? "אסמכתאות" : "אסמכתאות מהפסיקה", <BookOutlined />])

    header = <div className="truncate-text" style={{width: "100%"}}>
      <div><b>{brief && brief.title}</b></div>
      <div onClick={click}>{inTopic}: {brief.doc_title}</div>
    </div>

    if (BriefDrawerStore.mode === "refs")
      inner = <div className="brief-drawer" style={{minHeight: "80vh"}}>
        <Refs />
      </div>
    else {
      let modeTitle = brief.titles[BriefDrawerStore.mode === "claims" ? 0 : 1];
      inner = <div className="brief-drawer" style={{minHeight: "80vh", paddingBottom: Device.xs && '3rem'}}>
        <div style={{
          textAlign: "center",
          margin: "1rem 0",
          fontSize: "18px",
          fontWeight: 'bold',
          textDecoration: "underline"
        }}>
          {modeTitle}
        </div>
        {cs.map((c, i) => <div style={{marginBottom: "1rem"}} key={i}>
          {c[0] === "@" ? <b>{c.substr(1)}</b> : c}
        </div>)}
        <div className="flex justify-between">
          { withRefs ? <a onClick={() => handleModeChange("refs")}>אסמכתאות מהפסיקה</a> : <div />}
          <TextCopy text="העתק" copyText={cs.join("\n\n").replace(/@/g, "")} successMsg="הועתק!"/>
        </div>
      </div>;
    }
  }

  return <MyDrawer tabs={tabs} inner={inner} close={BriefDrawerStore.close} mode={BriefDrawerStore.mode} handleModeChange={handleModeChange} header={header} />;
})