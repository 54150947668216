import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Space, Card, Spin, Popover, Button, Divider, Skeleton} from "antd";
import {observable} from "mobx";
import User from "./User";
import {callWithCatch, pp} from "./utils";

import actioncable from 'actioncable';
import {OpenRelevancyDrawer} from "./ResultRelevancy.js";
import {Switch2} from "./Switcher.js";

let prefix = import.meta.env.DEV ? "ws://localhost:3007" : "wss://www.dinrega.com";

export const SearchBotStore = observable({
  store: null,
  status: null,
  uuid: null,
  close: () => {
    SearchBotStore.status = null;
    SearchBotStore.uuid = null;
  },
  open: async (store, uuid) => {
    SearchBotStore.uuid = uuid;
    SearchBotStore.store = store;
    SearchBotStore.status = { prep: true };

    const cable = actioncable.createConsumer(`${prefix}/cable`)
    cable.subscriptions.create({ channel: "AdvSearchsChannel", uuid }, {
      received(data) {
        // only until store has changed
        if (SearchBotStore.uuid === uuid)
          SearchBotStore.status = data;
      }
    });
  }
});

let TextPart = ({text, sup, hint, loadArticle}) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [missing, setMissing] = useState(false);
  // const [located, setLocated] = useState(false);

  let openChanger = async () => {
    let prevOpened = opened;
    setOpened(!opened);

    if (prevOpened)
      ;
    else
      await callWithCatch({
        url: "searches/locate",
        method: 'post',
        setLoading,
        params: {q: SearchBotStore.store.term, line: text},
        onSuccess: data => {
          if (data.doc) {
            setOpened(false);
            OpenRelevancyDrawer({
              doc: data.doc,
              loadArticle: loadArticle,
              cachedResult: data.result
            })
          }
          else
            setMissing(true);
        },
      })
  }

  // let content = located?.quote ?
  //     <div style={{whiteSpace: "pre-line"}}>{located.quote}</div> :
  //     <div style={{height: "150px", width: "250px"}}><Skeleton paragraph={{rows: 3}} active/></div>


  let content = missing ? <div style={{whiteSpace: "pre-line"}}>לא מזהה את המקור :(</div> : <Space><Spin/><span>מחפש את המקור...</span></Space>

  return <Popover
        // content={content}
        // title={loading ? <Space><Spin/><span>מחפש את המקור...</span></Space> : <span>{located.title}</span>}
        onOpenChange={openChanger}
        open={opened}
        onClose={close}
        trigger="click"
        content={content}
        overlayInnerStyle={{width: "200px"}}
        placement="right"
        >
        <a className="text-black hover:text-blue pl-1">{text}</a>
      </Popover>
      }

export const SearchBotSummary=observer(({part, loadArticle}) => {
    let status = SearchBotStore.status;

    if (!status)
      return null;

    let title = <Space>
      {status.status !== "done" && <Spin/>}
    </Space>

    let body = status.text;

    if (status.summary)
      body = <div className="space-y-4">
        {status.summary_lines.map((s, i) => <TextPart text={s} key={i} loadArticle={loadArticle}/>)}
        {status.issues?.map((issue, i) => <div className="rounded-none pb-2 bg-white" key={i}>
          <div className="font-bold">{issue.name}</div>
          <div>{issue.summary.map((s, j) => <TextPart {...s} key={j} loadArticle={loadArticle}/>)}</div>
          {/*<div className="text-sm hover-child"><Space split={<Divider type="vertical" />}><a>העתק פסקה</a><a>הצע חיפושי המשך</a><a>נסח טענות</a></Space></div>*/}
        </div>)}
      </div>

    return <div style={{whiteSpace: "pre-line", minHeight: "80vh"}}>
      <span>{title}</span>
      <span>{body}</span>
      <br/>
      <br/>
      {status.status === "done" &&
          <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את תשובותיו עם
            הפסיקה.</div>}
    </div>
  }
)
